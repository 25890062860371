.inner_container {
  display: flex;
  width: 100%;
  &__left_section {
    width: 65%;
    border-right: 1px solid #dadce0;
    overflow: auto;
    height: calc(100vh - 114px);
    .booking_section {
      padding-top: 5%;
      padding-left: 8%;
      padding-right: 8%;
      .booking_header_text{
        font-size: 34px;
      }
      .small_text {
        color: #5f6368;
        font-size: 16px;
        margin-bottom: 8px;
        &__12 {
          color: #5f6368;
          font-size: 12px;
          margin-bottom: 0;
        }
        &__22{
          color: #5f6368;
          font-size: 22px;
          margin-bottom: 0;
        }
      }
      .test_container{
        display: flex;
        flex-wrap: wrap;
        &__text{
          // padding: 5px;
          margin-right: 5px;
          color:#1539B0;
          margin-bottom: 0;
         
        }
        .price_text{
          padding: 5px;
          margin-right: 10px;
          margin-bottom: 0;
          background-color: #E8F0FE;
          color: #1A73E8;
          min-width: 50px;
          border-radius: 5px;
        }
      }
    }
  }
  &__right_section {
    width: 35%;
    overflow: auto;
    height: calc(100vh - 114px);
    .booking_section {
      padding-top: 5%;
      padding-left: 8%;
      padding-right: 8%;
      .right_section {
        .small_text {
          color: #5f6368;
          font-size: 16px;
          margin-bottom: 0;
          &__12 {
            color: #5f6368;
            font-size: 12px;
            margin-bottom: 0;
          }
        }
        padding-top: 10px;
        padding-bottom: 15px;
        border-bottom: 1px solid #dadce0;
      }
    }
  }
}
.w-40 {
  width: 40%;
}
.color-blue{
  color: #1A73E8 !important;
}