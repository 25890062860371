@import "@scss/common/_common-includes.scss";

.home-page-wrapper {
    margin-top: 82px;
    @media (max-width: $mobile-devices-max-width+px) {
        margin-top: 32px;
    }
    &__image {
        max-width: 594px;
        margin-top: 56px;
        @media (max-width: $mobile-devices-max-width+px) {
            margin-top: 32px;
            max-width: 350px;
        }
    }
}
