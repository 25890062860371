@import "@scss/common/_common-includes.scss";
.dashboard_main {
  // height: calc(100vh - 130px);
  height: calc(100vh - 121px);
  overflow: auto;
}
.homecontainer {
  display: flex;
  justify-content: center;
}
.dashboard-home-wrapper {
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  // grid-gap: 10px;
  // max-width: 1024px;

  width: 100%;
  margin: 0 auto;
}
.dashboard-card-wrapper {
  margin-top: 16px;
  margin-right: 16px;
  border: 1px solid #e0ecfc;
  // padding: 16px 32px 16px 16px;
  width: 428px;
  height: 84px;
  border-radius: $base-border-radius;
  @extend %transition;
  &:hover {
    // box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.29);
    transform: translateY(-2px);
  }
}
.dashboard-card-wrapper__title {
  font-size: (13 / $base-font-size) + rem;
  font-weight: $light-font-weight;
  margin-bottom: 4px;
  color: #616161;
}
.dashboard-card-wrapper__count {
  // font-size: (52 / $base-font-size) + rem;
  font-weight: $light-font-weight;
  line-height: 1.3;
}

.dashboard-wrapper__header {
  padding: 12px 24px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: $white-color;
  z-index: 3;
  position: sticky;
  top: 0;
}
.dashboard-wrapper__header-title {
  color: #3c4043;
  font-size: 20px;
  font-weight: 400;
}

//tables scss
.datatable_container {
  max-height: calc(100vh - 120px);
  overflow: auto;
  width: 100%;
  margin: auto;
  border-bottom: 1px solid #dadce0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.w-95 {
  width: 95%;
}

.datatable_table {
  overflow: auto;
}
.datatable_table_thead {
  .datatable_table_th {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 7;
    height: 60px;
    background-color: #fff;
    transition: all 500ms ease-in;
    font-family: $font-family;
    font-weight: 600;
    font-size: 12px;
    text-align: left;
    margin-left: 5px;
    padding-left: 10px;
    padding-right: 10px;
    h6 {
      font-weight: 500;
      color: #02052b;
      margin-bottom: 3px;
      font-size: 16px;
    }

    p {
      font-weight: 400;
      color: #5f6368;
      font-size: 12px;
      margin-bottom: 0;
    }
  }
}

.datatable_table_th:nth-child(1) {
  // text-align: center;
}

.datatable_table_td:nth-child(1) {
  // align-items: center;
  // text-align: center !important;
  // display: flex;
  // justify-content: center;
  // width: 70px !important;
  // border-bottom: 1px solid #efefef !important;
}
.datatable_table_tr {
  &:hover {
    background-color: #f7faff !important;
  }
  .datatable_table_td {
    font-family: $font-family;
    font-size: 14px;
    text-align: left;
    height: 73px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    // padding-bottom: 5px;
    border-bottom: 1px solid #efefef;
    width: 400px;
    cursor: pointer;
    max-width: 300px;
    h6 {
      font-weight: 500;
      color: #5f6368;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 3px;
    }
    p {
      font-weight: 400;
      color: #5f6368;
      font-size: 12px;
      margin-bottom: 0;
    }
  }
}
table {
  table-layout: fixed;
}
th,
td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}
th:after,
th:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}

th:before {
  // border-bottom: 1px solid #dadce0;
  top: -1px;
  // border-top: 1px solid #dadce0;
}

th:after {
  bottom: -1px;
  border-bottom: 2px solid #dadce0;
}
