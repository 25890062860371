@import "@scss/common/_common-includes.scss";
.dashboard-wrapper {
    // height: 100vh;
    min-height: 90vh;
    width: 100%;
    overflow: hidden;
}
.dashboard-header {
    // padding: 10px 0;
    height: 64px;
    display: flex;
    // overflow: hidden;
    border-bottom: 1px solid $theme-border-color;
    &__menu-icon {
        margin-right: 16px;
    }
    &__logedin-user {
        display: flex;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background-color: #1d4d40;
        cursor: pointer;
        &--dimention {
            height: 60px;
            width: 60px;
            margin-bottom: 8px;
        }
    }
    &__initial {
        font-size: (16 / $base-font-size) + rem;
        text-align: center;
        color: $white-color;
        margin: auto;
        &--font {
            font-size: (22 / $base-font-size) + rem;
        }
    }
}

.dashboard-header__account {
    padding: 24px;
    border-bottom: 1px solid $theme-border-color;
}
.sign-out-btn {
    max-width: 200px;
    width: 100%;
    margin: 24px auto;
}
.dashboard-header__dropdown-image {
    margin-right: 24px;
}
.dashboard-header__dropdown-list {
    padding: 24px 0;
    border-bottom: 1px solid $theme-border-color;
}
.dashboard-header__dropdown-dashboard-link {
    padding: 8px 24px;
    display: block;
    @extend %transition;
    &:hover {
        background-color: $theme-border-color;
    }
}
.dropdown-menu.show {
    min-width: 320px;
    width: 100%;
    padding: 0;
}
button.btn-secondary {
    background-color: $white-color;
    border-color: transparent;
    &:hover {
        color: #fff;
        background-color: $white-color;
        border-color: transparent;
        outline: none;
    }
    &:focus {
        color: #fff;
        background-color: $white-color;
        border-color: transparent;
        outline: none;
    }
}

.dashboard-navigation {
    margin-top: 16px;
    min-width: 255px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    opacity: 1;
    transition: none;
    &--collapse {
        min-width: 0;
        overflow: hidden;
        width: 0;
        opacity: 0;
        transition: all 0.5s ease-in-out;
    }
    ul{
        padding-left: 0;
        .nav_text{
            font-size: (14 / $base-font-size) + rem;
            color:#423F3F
        }
    }
}
.dashboard-navigation__image {
    margin-right: 22px;
    margin-left: 4px;
    height: 20px;
}
.dashboard-navigation__link-nav {
    display: flex;
    align-items: center;
    font-size: (14 / $base-font-size) + rem;
    padding: 5px 0 5px 26px;
    width: 100%;
    @extend %transition;
    &:hover {
        background-color: #f2f3fa;
        border-radius: 0 20px 20px 0;
        color: $theme-primary-color;
    }
    &--active {
        background-color: #f2f3fa !important;
        border-radius: 0 20px 20px 0;
        color: $theme-primary-color;
        .nav_text{
            color: $theme-primary-color !important;
        }
    }
}
.dashboard-navigation__list {
    margin-right: 10px;
    font-size: (14 / $base-font-size) + rem;
    margin-bottom: 14px;
}
.dashboard-navigation__icon {
    border-top: 1px solid $theme-border-color;
    justify-content: flex-end;
}
.dashboard-navigation__image-icon {
    border-top: 1px solid $theme-border-color;
    margin: 10px 3px 10px 0;
    background-color: #dbdce0;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    font-size: 1rem;
    padding: 4px;
}

.dashboard-main-wrapper {
    border-left: 1px solid $theme-border-color;
    // max-height: 100vh;
    // overflow-y: auto;
    width: 100%;
    // padding: 25px;
}
.dashboard-main-wrapper-header {
    border-bottom: 1px solid $theme-border-color;
    padding-bottom: 14px;
    margin-bottom: 24px;
}
.dashboard-main-wrapper-header__title {
    font-size: (24 / $base-font-size) + rem;
    font-weight: $normal-font-weight;
}
.dashboard-main-wrapper-header__select-date {
    border: none;
    color: #5f6368;
    margin-right: 4px;
    font-size: (17 / $base-font-size) + rem;
}

.dashboard-main-wrapper-card {
    border: 1px solid $theme-border-color;
    padding: 16px;
    border-radius: 4px;
    margin: 0 0 15px;
    @extend %transition;
    &:hover {
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.29);
        transform: translateY(-2px);
    }
}
.dashboard-main-wrapper-card__details {
    margin-left: 8px;
}
.dashboard-main-wrapper-card__list {
    .dot {
        height: 4px;
        width: 4px;
        background-color: $theme-font-secondary-color;
        border-radius: 50%;
    }
    ul {
        display: flex;
        align-items: center;
        margin-bottom: 3px;
        li {
            &:not(:last-child) {
                margin-right: 5px;
            }
            font-size: (16 / $base-font-size) + rem;
            color: #3c4043;
            font-weight: $normal-font-weight;
        }
    }
}
.dashboard-main-wrapper-card__list--font {
    .dot {
        height: 4px;
        width: 4px;
        background-color: #5f6368;
        border-radius: 50%;
    }
    ul {
        li {
            font-weight: $light-font-weight;
            color: #5f6368;
            font-size: (12 / $base-font-size) + rem;
        }
    }
}

.dashboard-footer-wrapper {
    position: fixed;
    background-color: $white-color;
    bottom: 0;
    width: 100%;
    padding: 16px 0;
    border-top: 1px solid $theme-border-color;

    ul {
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }
}
.dashboard-footer-wrapper__list {
    display: inline-block;
    &:not(:last-child) {
        margin-right: 8px;
        padding-right: 8px;
        border-right: 1px solid $theme-border-color;
    }
}
.dashboard-footer-wrapper__footer-link {
    font-size: (14 / $base-font-size) + rem;
    color: $theme-primary-color;
}
.dashboard-footer-wrapper__footer-link--color {
    color: #5f6368;
}
.fresh-leads-wrapper {
    max-width: 500px;
}

.form-control.date-picker {
    max-width: 300px;
    height: 41px;
    text-align: center;
}

.date-picker-wrapper li {
    &:not(:last-child) {
        margin-right: 8px;
    }
}
