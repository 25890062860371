.shimmer-circle {
	width: 32px;
	height: 32px;
	display: inline-block;
	border-radius: 50%;
}
.shimmer-button {
	width: 90px;
	height: 40px;
	display: inline-block;
}
.animate-loader {
	animation: shimmer 3s infinite;
	background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
	background-size: 1000px 100%;
}
@keyframes shimmer {
	0% {
		background-position: -1000px 0;
	}
	100% {
		background-position: 1000px 0;
	}
}
.dashboard-main-wrapper-card__image-loading {
	width: 3.125rem;
	height: 3.125rem;
	border-radius: 50%;
}
.dashboard-main-wrapper-card__badge {
	height: 1rem;
	width: 7.5rem;
	border-radius: 0.5rem;
}
.dashboard-main-wrapper-card__badge--large {
	width: 180px;
}
