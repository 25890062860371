@import "@scss/common/_common-includes.scss";

.login-wrapper {
    max-width: 364px;
    width: 100%;
    @media (max-width: $mobile-devices-max-width+px) {
        max-width: 100%;
    }
    &__title {
        font-size: (36 / $base-font-size) + rem;
        font-weight: $normal-font-weight;
        margin-bottom: 32px;
        @media (max-width: $mobile-devices-max-width+px) {
            margin-bottom: 28px;
        }
    }
    &__logo-img {
        margin-bottom: 32px;
        @media (max-width: $mobile-devices-max-width+px) {
            margin-bottom: 24px;
        }
    }
    &__cta-btn {
        border: 1px solid transparent;
        border-radius: 4px;

        &--theme-primary-btn {
            background-color: $theme-primary-color;
            color: $white-color;
            padding: 9px 25px;
            font-weight: $light-font-weight;
            font-size: 12px;
            @extend %transition;
            &:hover {
                color: $theme-primary-color;
                background-color: $white-color;
                border-color: $theme-primary-color;
            }
            min-width: 140px;
        }
        &--theme-secondary-btn {
            color: $theme-primary-color;
            background-color: $white-color;
            border: 1px solid #dadce0;
            padding: 9px 25px;
            font-size: 12px;
            min-width: 140px;
        }
    }
}

.form-group input.form-control {
    &.login-wrapper__form-element {
        height: 56px;
        margin: 4px 0 20px;
    }
}
