.timeslot_container {
  display: flex;
  width: 100%;
  // background-color: red;
  // min-height: 80vh;
  .timeslot_container_left {
    width: 70%;
    border-right: 1px solid #dadce0;
    height: calc(100vh - 121px);
    overflow: auto;
  }
  .timeslot_container_right {
    width: 30%;
    height: calc(100vh - 121px);
    overflow: auto;
  }
}
.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: gray;
}
.dot_small {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: gray;
}

.time_slot_list_container {
  flex-wrap: wrap;
  display: flex;
  margin: 0 auto;
  padding: 2%;
  .item {
    margin: 5px;
    width: 140px;
    cursor: pointer;
    border: 1px solid #dadce0;
    border-radius: 5px;
    padding: 5px;
    transition: all ease 300ms;
    .item_p {
      margin-bottom: 5px;
      font-size: 12px;
    }
    &:hover {
      background-color: #f4f8ff;
    }
  }
}
.header_text{
  margin-bottom: 0;
  font-size: 16px;
}
.text{
  margin-bottom: 4px;
  font-size: 12px;
}

//--------- Date Picker ------------//

//datepicker scss

.react-datepicker__header {
  text-align: center;
  background-color: white !important;
  border-bottom: 1px solid #efefef !important;
  border-top-left-radius: 0.3rem;
  padding: 10px 0;
  position: relative;
}
.react-datepicker__day-names {
  margin-bottom: -8px;
  padding: 10px !important;
  width: 300px !important;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  /* color: #000; */
  font-weight: bold;
  font-size: 0.944rem;
  margin-top: 10px !important;
  display: none !important;
}
.react-datepicker__navigation--next {
  right: -3px;
  top: 20px !important;
}
.react-datepicker__navigation--previous {
  left: -3px;
  top: 20px !important;
}
.react-datepicker__day--selected {
  /* border-radius: 0.3rem; */
  border-radius: 0 !important;
  background-color: #3369e8 !important;
  color: #ffffff !important;
  border: 1px solid #3369e8 !important;
  font-weight: 800;
  border-radius: 50% !important;
  &:hover {
    color: #000 !important;
  }
}
.react-datepicker__day-name {
  color: rgb(81, 111, 144) !important;
  font-weight: 800;
  font-size: 14px;
  width: 1.7rem;
  line-height: 1.7rem;
}
.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
  margin: 0 auto;
  &:hover {
    border-radius: 0px !important;
    /* background-color: #408dfb !important; */
    border-radius: 50% !important;
    color: white;
    outline: 1px solid #408dfb !important;
    background-color: transparent !important;
    color: black;
  }
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  display: none !important;
}
.react-datepicker__navigation-icon::before {
  /* display: none !important; */
}
.react-datepicker__month-read-view {
  margin-top: 10px !important;
}
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: white !important;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 45px !important;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: none !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}
.react-datepicker__year-option {
  height: 28px !important;
  &:hover {
    background-color: #f7faff !important;
  }
}
.react-datepicker__month-option {
  /* padding: 5px; */
  height: 28px !important;
  &:hover {
    background-color: #f7faff !important;
  }
}
/*  */

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid #efefef !important;
  width: 70px;
  padding: 5px;
  text-align: center;
  border-radius: 0.3rem;
  position: relative;
  visibility: visible !important;
}
.react-datepicker__year-select,
.react-datepicker__month-select {
  border: 1px solid #efefef !important;
  border-radius: 3px !important;
  margin-top: 8px !important;
  // min-width: 80px;
  padding: 5px;
}

//Inline edit datepicker
.form_control-inline_datepicker {
  background-color: #f4f8ff;
  // border: 1px solid lightgray;
  border: 1px solid #efefef;
  padding: 8px;
  border-radius: 2px;
  padding-left: 15px;
  width: auto;
  font-size: 14px;
  // font-family: $font-family;
  color: #423f3f;
  &:focus {
    border-radius: 5px;
  }
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: none !important;
  // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker__day {
  color: #000;
  display: inline-block;
  width: 1.7rem !important;
  line-height: 1.7rem !important;
  text-align: center;
  margin: 0.166rem !important;
}
.react-datepicker__day--disabled {
  cursor: default;
  color: #ccc !important;
}

.time-picker-slot {
  border: 1px solid #dadce0;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
}


.common-sticky-header {
	background-color: white;
	position: sticky;
	z-index: 7;
	top: 6px;
  border-bottom: 1px solid #dadce0;
}